// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-illustrations-bloom-js": () => import("./../../../src/pages/illustrations/bloom.js" /* webpackChunkName: "component---src-pages-illustrations-bloom-js" */),
  "component---src-pages-illustrations-burst-js": () => import("./../../../src/pages/illustrations/burst.js" /* webpackChunkName: "component---src-pages-illustrations-burst-js" */),
  "component---src-pages-illustrations-jellyfish-js": () => import("./../../../src/pages/illustrations/jellyfish.js" /* webpackChunkName: "component---src-pages-illustrations-jellyfish-js" */),
  "component---src-pages-illustrations-js": () => import("./../../../src/pages/illustrations.js" /* webpackChunkName: "component---src-pages-illustrations-js" */),
  "component---src-pages-illustrations-skull-js": () => import("./../../../src/pages/illustrations/skull.js" /* webpackChunkName: "component---src-pages-illustrations-skull-js" */),
  "component---src-pages-illustrations-wet-js": () => import("./../../../src/pages/illustrations/wet.js" /* webpackChunkName: "component---src-pages-illustrations-wet-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-projects-chatbot-js": () => import("./../../../src/pages/projects/chatbot.js" /* webpackChunkName: "component---src-pages-projects-chatbot-js" */),
  "component---src-pages-projects-die-by-dye-js": () => import("./../../../src/pages/projects/die-by-dye.js" /* webpackChunkName: "component---src-pages-projects-die-by-dye-js" */),
  "component---src-pages-projects-graphic-design-js": () => import("./../../../src/pages/projects/graphic-design.js" /* webpackChunkName: "component---src-pages-projects-graphic-design-js" */),
  "component---src-pages-projects-image-reproduction-js": () => import("./../../../src/pages/projects/image-reproduction.js" /* webpackChunkName: "component---src-pages-projects-image-reproduction-js" */),
  "component---src-pages-projects-image-technology-js": () => import("./../../../src/pages/projects/image-technology.js" /* webpackChunkName: "component---src-pages-projects-image-technology-js" */),
  "component---src-pages-projects-information-visualization-js": () => import("./../../../src/pages/projects/information-visualization.js" /* webpackChunkName: "component---src-pages-projects-information-visualization-js" */),
  "component---src-pages-projects-keylogger-js": () => import("./../../../src/pages/projects/keylogger.js" /* webpackChunkName: "component---src-pages-projects-keylogger-js" */),
  "component---src-pages-projects-openspace-js": () => import("./../../../src/pages/projects/openspace.js" /* webpackChunkName: "component---src-pages-projects-openspace-js" */),
  "component---src-pages-projects-portfolio-js": () => import("./../../../src/pages/projects/portfolio.js" /* webpackChunkName: "component---src-pages-projects-portfolio-js" */),
  "component---src-pages-projects-post-it-js": () => import("./../../../src/pages/projects/post-it.js" /* webpackChunkName: "component---src-pages-projects-post-it-js" */),
  "component---src-pages-projects-procedural-planet-js": () => import("./../../../src/pages/projects/procedural-planet.js" /* webpackChunkName: "component---src-pages-projects-procedural-planet-js" */),
  "component---src-pages-projects-roller-coaster-simulation-js": () => import("./../../../src/pages/projects/roller-coaster-simulation.js" /* webpackChunkName: "component---src-pages-projects-roller-coaster-simulation-js" */),
  "component---src-pages-projects-tetris-js": () => import("./../../../src/pages/projects/tetris.js" /* webpackChunkName: "component---src-pages-projects-tetris-js" */),
  "component---src-pages-projects-vr-stranded-js": () => import("./../../../src/pages/projects/vr-stranded.js" /* webpackChunkName: "component---src-pages-projects-vr-stranded-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-work-ericsson-js": () => import("./../../../src/pages/work/ericsson.js" /* webpackChunkName: "component---src-pages-work-ericsson-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */),
  "component---src-pages-work-media-technology-days-js": () => import("./../../../src/pages/work/media-technology-days.js" /* webpackChunkName: "component---src-pages-work-media-technology-days-js" */),
  "component---src-pages-work-mette-js": () => import("./../../../src/pages/work/mette.js" /* webpackChunkName: "component---src-pages-work-mette-js" */),
  "component---src-pages-work-we-know-it-js": () => import("./../../../src/pages/work/we-know-it.js" /* webpackChunkName: "component---src-pages-work-we-know-it-js" */)
}

